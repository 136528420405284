import type { ResponsiveModalContainerProps } from '@/providers/modal/responsiveModal';
import { ResponsiveModalContainer } from '@/providers/modal/responsiveModal';
import { useFormikContext } from 'formik';
import type { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

export function ModalFormWrapper( {
	title,
	name,
	...props
}: {
	name?: string,
	title?: string,
	children: ReactNode
} & Omit<ResponsiveModalContainerProps, 'onClose'> ) {
	const { t } = useTranslation();
	const formik = useFormikContext<any>();
	return (
		<ResponsiveModalContainer
			loading={formik?.isSubmitting}
			title={title || `${formik?.values?.id ? t( 'common:edit' ) : t( 'common:create' )} ${name}`}
			closeOnSave={false}
			onSave={formik?.handleSubmit}
			{...props}
		/>
	);
}
