import FormattedTextField, { FormattedTextFieldProps } from '@/components/formattedTextField';
import { timezones } from '@/pages/dashboard/management/locations/form/timzeZones';
import { Autocomplete } from '@mui/material';
import { useField } from 'formik';

export default function TimezoneSelect( {
	name,
	textFieldProps,
}: {
	name: string,
	textFieldProps?: FormattedTextFieldProps
} ) {
	const [ , { value }, { setValue } ] = useField<any>( name );
	
	return (
		<Autocomplete
			value={value || ''}
			options={timezones}
			renderInput={( params ) => (
				<FormattedTextField
					{...params}
					fullWidth
					label='Timezone'
					size='medium'
					{...textFieldProps}
				/>
			)}
			onChange={( e, newValue ) => setValue( newValue )}
			onInputChange={async ( e, newValue ) => {
				if ( newValue === '' ) {
					await setValue( '' );
				}
			}}
		/>
	);
}
