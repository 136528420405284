import PageSection from '@/components/page/section';
import { GatewaysRead } from '@/data/gateway.graphql';
import GraphqlProvider from '@/data/query/graphqlProvider';
import type { GatewayMultiOutput, QueryGatewaysReadArgs } from '@/types/schema';
import { ListItemText, MenuItem, Select, Typography } from '@mui/material';
import { useFormikContext } from 'formik';
import { useTranslation } from 'react-i18next';

export default function GatewayComponent() {
	const formik = useFormikContext<any>();
	const { t } = useTranslation();
	const selectedGateway = formik.values.gateway;
	return (
		<GraphqlProvider<GatewayMultiOutput, QueryGatewaysReadArgs>
			queryKey='gateway'
			query={GatewaysRead}
			variables={{ options: { filter: { active: true, external: { $ne: 'QUICKBOOKS' } } } }}>
			{( { items: gateways } ) => (
				<PageSection
					primary={t( 'common:gateway' )}
					secondary={(
						<Typography color='text.secondary'>
							{t( 'common:pg-one' )} <br/> {t( 'common:pg-two' )}
						</Typography>
					)}>
					<Select fullWidth value={selectedGateway?.id || 'None'} sx={{ my: 1 }}>
						<MenuItem value='None' onClick={() => formik.setFieldValue( 'gateway', 'None' )}>None</MenuItem>
						{gateways.map( ( gateway, i ) => (
							<MenuItem key={i} value={gateway.id} onClick={() => formik.setFieldValue( 'gateway', gateway )}>
								<ListItemText
									primary={gateway.external.toLowerCase()}
									primaryTypographyProps={{ sx: { textTransform: 'capitalize' } }}
									secondary={gateway.locations.map( ( {
										name,
										address,
									} ) => name || address.line1 ).join( ', ' )}
								/>
							</MenuItem>
						) )}
					</Select>
				</PageSection>
			)}
		</GraphqlProvider>
	);
}
