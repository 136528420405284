import FormattedTextField from '@/components/formattedTextField';
import { ResponsiveModalContainer } from '@/providers/modal/responsiveModal';
import { Stack } from '@mui/material';
import { useState } from 'react';
import validator from 'validator';

export default function LinkModal( { editor, link, text } ) {
	const [ internalLink, setInternalLink ] = useState<string | null>( link || '' );
	const [ internalText, setInternalText ] = useState<string | null>( text || '' );
	console.log( text );
	return (
		<ResponsiveModalContainer
			title='Enter Link'
			saveButtonText='Apply Link'
			saveButtonProps={{ disabled: !internalLink || !validator.isURL( internalLink ) }}
			onSave={() => {
				const selection = editor.view.state.selection;
				editor
					.chain()
					.focus()
					.extendMarkRange( 'link' )
					.setLink( { href: internalLink } )
					.insertContentAt( { from: selection.from, to: selection.to }, internalText )
					.run();
			}}>
			<Stack spacing={2}>
				<FormattedTextField
					fullWidth
					placeholder='Link Text'
					label='Text'
					value={internalText}
					onChange={( e ) => setInternalText( e.target.value )}
				/>
				<FormattedTextField
					fullWidth
					placeholder='https://example.com'
					label='Link'
					value={internalLink}
					onChange={( e ) => setInternalLink( e.target.value )}
				/>
			</Stack>
		</ResponsiveModalContainer>
	);
}

