import TipTapStylesWrapper from '@/components/tiptap/stylesWrapper';
import { BoxProps } from '@mui/material';
import Blockquote from '@tiptap/extension-blockquote';
import Code from '@tiptap/extension-code';
import { Color } from '@tiptap/extension-color';
import HorizontalRule from '@tiptap/extension-horizontal-rule';
import Link from '@tiptap/extension-link';
import TextAlign from '@tiptap/extension-text-align';
import TextStyle from '@tiptap/extension-text-style';
import Underline from '@tiptap/extension-underline';
import { EditorContent, useEditor } from '@tiptap/react';
import StarterKit from '@tiptap/starter-kit';
import { useEffect } from 'react';

export default function TipTapTypography( { children, ...props }: { children: any } & BoxProps ) {
	const editor = useEditor( {
		extensions: [
			StarterKit,
			Underline,
			Code,
			Blockquote,
			HorizontalRule,
			Color,
			TextStyle,
			TextAlign.configure( {
				types: [ 'heading', 'paragraph' ],
			} ),
			Link.configure( {
				openOnClick    : true,
				autolink       : true,
				defaultProtocol: 'https',
			} ),
		],
		editable  : false,
		content   : children,
	} );
	
	useEffect( () => {
		if ( editor && children !== editor.getHTML() ) {
			editor.commands.setContent( children ); // Update the editor content
		}
	}, [ children, editor ] );
	
	return (
		<TipTapStylesWrapper {...props}>
			<EditorContent editor={editor} className='tiptap-editor'/>
		</TipTapStylesWrapper>
	);
	
}

